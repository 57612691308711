.adminContainer {
  display: flex;
}

.adminContainer .loading {
  margin: auto;
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adminContainer .selectUser {
  position: relative;
  margin-top: calc(50% - 80px);
  max-height: calc(100vh - 100px);
  text-align: center;
}

.leftContainer {
  flex: 1;
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 30px;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #39a686 transparent;
  font-size: 1.5vh;
}

.leftContainer .tableHeader {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: transparent;
  border: none;
  margin-left: 12px;
  margin-right: 11px;
  padding-right: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.leftContainer .tableHeader table {
  border-color: "transparent";
  margin-bottom: 0px;
  table-layout: fixed;
  color: #39a686;
}

.leftContainer .tableHeader table th {
  width: calc(100% / 3);
  font-weight: normal;
  border: none;
}

.leftContainer .tableHeader table .editButtonWrapper {
  width: 12vh;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.leftContainer .tableContent {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  padding-right: 5px;
  scrollbar-gutter: stable;
  scrollbar-width: thin;
  scrollbar-color: #39a686 transparent;
}

.leftContainer .tableContent table {
  table-layout: fixed;
}

.leftContainer .tableContent table td {
  width: calc(100% / 3);
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  border: none;
  white-space: nowrap;
}

.leftContainer .tableContent table td:first-child {
  width: 55%;
}
.leftContainer .tableHeader table th:first-child {
  width: 55%;
}

.leftContainer .tableContent table .usersColumn {
  width: 10vh;
  text-align: center;
}
.leftContainer .tableHeader table .usersColumn {
  width: 10vh;
  text-align: center;
}

.leftContainer .tableContent table .userIcon {
  margin-right: 1.5vh;
  margin-left: 3px;
}

.leftContainer .tableContent table .editButtonWrapper {
  width: 12vh;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.leftContainer .tableContent table button {
  display: "flex";
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3.5vh;
  max-height: 40px;
  max-width: 100%;
  border-radius: 10px;
  font-family: "VirtualLabQuickSandMedium";
  margin-left: auto;
  margin-right: 10px;
  outline: none;
  box-shadow: none;
  padding: 0;
}

.containerFluid {
  padding-right: 0px;
}

.rightContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  margin-top: 60px;
  max-height: calc(100vh - 300px);
  margin-right: 40px;
  overflow-y: auto;
  font-size: 1.5vh;
}

.rightContainer .containerHeaderWrapper {
  position: absolute;
  width: 98%;
  right: 20px;
  padding-top: 15px;
  background-color: #eff0f2;
  z-index: 9998;
}

.rightContainer .containerHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 20px;
}

.userDataImage {
  width: 20%;
  max-width: 30px;
  margin-right: 2vh;
}

.userDataWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10px;
  line-height: 1;
}

.userDataName {
  text-align: right;
  padding-bottom: 3px;
}

.userDataEmail {
  text-align: right;
  font-family: "VirtualLabQuickSandLight";
}

.rightContainer .containerHeaderWrapper hr {
  width: 100%;
  margin-top: 15px;
  height: 2px;
}

.rightContainer .containerHeaderWrapper .containerHeaderTitle {
  text-align: center;
}

.rightContainer .containerHeaderWrapper .containerHeaderDescription {
  text-align: center;
  font-family: "VirtualLabQuickSandLight";
  margin-top: -15px;
}

.rightContainer .trainingsContainer {
  text-align: left;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 130px);
  padding-top: 160px;
}

.rightContainer .trainingsContainer .form-check-label {
  font-family: "VirtualLabQuickSandLight";
  font-weight: bold;
  margin-top: 0px;
}

.rightContainer .trainingsContainer .trainingsGroup li {
  margin-left: -32px;
}

.rightContainer .trainingsContainer .trainingsGroupTitle {
  margin-bottom: 8px;
  color: #39a686;
}

/**/

.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other content */
}

.modal-content {
  background-color: #eff0f2;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Box shadow for depth */
  width: 50vh;
  font-size: 1.5vh;
}

.modal-content p {
  font-family: "VirtualLabQuickSandBold";
  font-size: 1.5em;
  text-align: center;
  margin-top: 2vh;
  margin-bottom: 1vh;
}

.modal-content label {
  font-family: "VirtualLabQuickSandMedium";
  text-align: center;
}

.modal-content .modalUserContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10vh;
  max-height: 1%;
}

.modal-content .button-container {
  display: flex;
  justify-content: center;
  margin-top: 10vh;
}

.modal-content .button-container button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 3.5vh;
  max-height: 40px;
  max-width: 100%;
  border-radius: 10px;
  font-family: "VirtualLabQuickSandMedium";
  margin: 15px;
}

.modal-content .button-container .buttonCancel {
  background-color: #cccccc;
  border-color: #cccccc;
  color: #404040;
}

.modal-content .button-container .buttonAccept {
  background-color: #a63939;
  border-color: #a63939;
}

.dropdownMenu {
  position: absolute;
  top: 120%;
  right: 0;
  z-index: 10000;
  background-color: #39a686;
  border: 1px solid #39a686;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  min-width: max-content;
  font-family: VirtualLabQuickSandLight;
  font-weight: bold;
}

.dropdownMenu button {
  width: auto;
  color: #fff;
  text-align: right;
}

.dropdownMenu button img {
  width: 1.8vh;
  min-width: 5px;
  margin-right: 1vh;
  margin-left: 2vh;
}
