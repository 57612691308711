.containerHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.userName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10px;
  line-height: 1;
  text-align: left;
}

.detailsButtonWrapper {
  width: 10%;
  overflow: hidden;

  text-align: right;
}

.detailsButtonWrapper button {
  background-color: #39a686;
  border-color: #39a686;
  margin-right: 1vh;
}

.detailsButtonWrapper button:hover,
.detailsButtonWrapper button:active,
.detailsButtonWrapper button:focus {
  background-color: #39a686;
  border-color: #39a686;
}

.filter-panel {
  position: absolute;
  z-index: 999;
  width: 25vh;
  background-color: #cccccc;
  border-radius: 10px;
  margin-left: -3px;
}

.filter-last-panel {
  position: absolute;
  z-index: 999;
  width: 25vh;
  background-color: #cccccc;
  border-radius: 10px;
  margin-left: -50px;
}

/* Styles for the filters checkbox */

.filters-form-check {
  display: block;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.filters-checkbox {
  display: block;
  position: relative;
  margin-left: 1vh;
}

.filters-checkbox-label {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5vh;
  color: #212529;
}

.filters-checkbox-label input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.filters-checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 1.6vh;
  width: 1.6vh;
  background-color: #fff;
  border: 1px solid #ccc;
  position: absolute;
  max-width: 15px;
  max-height: 15px;
  min-width: 14px;
  min-height: 14px;
  border-radius: 4px;
}

.filters-checkbox-label
  input[type="checkbox"]:not(:checked)
  + .checkmark::after {
  content: "";
  display: none;
}

.filters-checkbox-label
  input[type="checkbox"]:checked
  + .filters-checkmark::after {
  content: "\2714";
  font-size: 1.2em;
  color: #212529;
  display: block;
  position: absolute;
  top: 1px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
}
