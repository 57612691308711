body {
  font-family: "VirtualLabQuickSandBold";
  font-weight: normal;
  background-color: #eff0f2;
  font-size: 0.7em;
}

.btn {
  font-size: 1em;
}

label {
  font-size: 1em;
}

input {
  font-size: 0.1em;
}

.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 22px;
  margin-left: 20px;
  margin-top: 5px;
}

.custom-checkbox input {
  display: none;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  max-height: 40px;
  border: 3px solid #cccccc;
  background-color: #cccccc;
  border-radius: 4px;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #404040;
  border: 3px solid #cccccc;
  border-radius: 5px;
}

.thin-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #39a686 transparent;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .btn,
  label,
  body {
    font-size: 0.6em;
  }
}

/* Media query for even smaller screens */
@media screen and (max-width: 480px) {
  .btn,
  label,
  body {
    font-size: 0.5em;
  }
}

@font-face {
  font-family: "VirtualLabQuickSandBold";
  src: url("../public/fonts/QUICKSAND-BOLD.TTF") format("truetype");
}

@font-face {
  font-family: "VirtualLabQuickSandMedium";
  src: url("../public/fonts/QUICKSAND-MEDIUM.TTF") format("truetype");
}

@font-face {
  font-family: "VirtualLabQuickSandLight";
  src: url("../public/fonts/QUICKSAND-LIGHT.TTF") format("truetype");
}
